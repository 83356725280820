import React, { useEffect } from "react";
import { Link } from "gatsby";
import { SEO } from "components";
import { Blurb, SponsorList } from "components/sponsors";
import { PageHeading } from "components/layout";
import useMenu from "state/useMenu";
/* Sponsors Page
============================================================================= */

const SponsorsPage = ({ data }) => {
	// Config
	const { setAd } = useMenu();

	useEffect(() => {
		setAd();
	}, []);
	// Render Podcast Sponsors
	const renderSponsorPodcasts = () => {
		return data.allSanityPodcast.nodes.map((podcast) => {
			if (!podcast.podcast_sponsors.length) return null;
			return (
				<SponsorList
					key={podcast.name}
					title={podcast.name}
					sponsors={podcast.podcast_sponsors}
				/>
			);
		});
	};

	return (
		<>
			<SEO title="Sponsors" />
			<SponsorList sponsors={data.allSanitySponsor.nodes} />
		</>
	);

	// Render
};

/* Page Query
============================================================================= */

export const pageQuery = graphql`
	{
		allSanitySponsor {
			nodes {
				name
				website
				description
				image {
					image {
						asset {
							localFile {
								childImageSharp {
									fluid(maxWidth: 750) {
										src
										srcSet
									}
								}
							}
						}
					}
				}
			}
		}
		allSanityPodcast {
			nodes {
				name
				podcast_sponsors {
					name
					website
					description
					image {
						alt
						image {
							asset {
								localFile {
									childImageSharp {
										fluid {
											src
											srcSet
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

/* Export
============================================================================= */

export default SponsorsPage;
